import React from "react"
import Layout from '../layouts/layout'

const NotFound = () => {
  
  return (
    <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
    <div className="container">
      <div className="row align-item-center">
        <div className="col-lg-12">
            <div className="error-inner">
              <img src="/images/logo/logo.png" width="250"/>
              <h2>페이지 준비중입니다. <br /> We're making changes!</h2>
              <p className="description">관련자료 준비중에 있습니다. 빠른시일 안에 정상 서비스 하도록 하겠습니다. <br /> The page admin is updating the website for now. Please check again later</p>                
            </div>
        </div>
      </div>
    </div>
  </div>
    // <Layout>
    //   <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
    //     <div className="container">
    //       <div className="row align-item-center">
    //         <div className="col-lg-12">
    //           <div className="error-inner">
    //             <h1>Welcome</h1>
    //             <h2 className="title">This is onboarding page</h2>
    //             <p className="description"> Welcome to Alphacode. Check out our home page!</p>               
    //             <div className="view-more-button">
    //                 <a className="btn-default" href="/">Go Back Home</a>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Layout>
  )
}

export default NotFound
